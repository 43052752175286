<template>
  <div class="visitSurveyList">
    <threeLevelMenuOrg @oneChange="oneChange" @twoChange="twoChange" @threeChange="threeChange"></threeLevelMenuOrg>
    
    <div v-if="nosite" class="nodata-wrap">
      <img :src="nodata" alt="" class="nodata-img">
      <div>暂无调研</div>
    </div>
    <div v-else class="data-wrap">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div class="visit-item" v-for="(item, index) in dataList" :key="index" @click="visitSubClick(item)">
            <div class="title">{{item.taskDesc}}</div>
            <div class="flex desc">
              <div>{{item.userName}}</div>
              <div>{{item.submitTime}}</div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="nextBtn flex"  v-if="isShow_Survey_NewSurvey">
      <van-button class="publicBtnActive" @click="newvisitSurveyClick">新建走访调研</van-button>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import { getProbeTaskList} from '@/utils/api'
import common from '@/utils/common'
import {createProbeTask, selectSubTaskList} from "../../utils/api"
import threeLevelMenuOrg from '@/components/threeLevelMenuOrg'
import {uwStatisticAction} from "../../utils/util";
export default {
  components: {
    threeLevelMenuOrg
  },
  data() {
    return{
      nodata: require('@/assets/img/nodata/nodata.png'),
      nosite: false,
      dataList:[],
      loading: false,
      finished: false,
      pageIndex: 1,
      pageSize: 10,
      isLoading: false,
      deptNo: '',
    }
  },
  computed:{
    ...mapGetters([
      'isShow_Survey_NewSurvey'
    ])
  },
  mounted() {
    uwStatisticAction('/visitSurveyList','走访调研任务列表')
  },
  methods:{
    visitSubClick(item){
      selectSubTaskList({taskNo: item.taskNo}).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200 && res.data.data.length == 1){
            if (common.getItem('uniqueNo') === item.uniqueNo){//是自己建的能修改
              this.$router.push({name: 'surveyInput', query:{isOneSubTask:'y',taskNo: item.taskNo,subTaskCode:res.data.data[0].taskCode,taskId:res.data.data[0].id}})
            }else {
              this.$router.push({name: 'surveyDetail', query:{taskNo: item.taskNo,subTaskCode:res.data.data[0].taskCode,taskId:res.data.data[0].id}})
            }

          }

          if(res.data && res.data.businessCode == 200 && res.data.data.length > 1){
            if (common.getItem('uniqueNo') === item.uniqueNo){
              this.$router.push({name: 'visitsubSurveyListInput', query:{taskNo:item.taskNo,areaCode: item.areaCode}})
            }else {
              this.$router.push({name: 'visitsubSurveyListDetail', query:{taskNo:item.taskNo}})

            }

          }
        }
      })
    },
    newvisitSurveyClick(){
      createProbeTask({}).then(res=>{
        if (res.code === 200 ){
          selectSubTaskList({taskNo: res.data.data}).then(subRes=>{
            if(subRes.code == 200){
              if(subRes.data && subRes.data.businessCode == 200 && subRes.data.data.length == 1){

                this.$router.push({name: 'surveyInput', query:{isOneSubTask:'y',taskNo: res.data.data,subTaskCode:subRes.data.data[0].taskCode,taskId:subRes.data.data[0].id}})

              }

              if(subRes.data && subRes.data.businessCode == 200 && subRes.data.data.length > 1){

                this.$router.push({name: 'visitsubSurveyListInput', query:{taskNo:res.data.data}})


              }
            }
          })
        }

      })

    },
    toGetProbeTaskList(val) {
      getProbeTaskList({
        deptNo: val ? val : '',
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }).then(res=>{
        this.loading = false
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200) {
            this.finished = !res.data.data.hasNextPage;
            if (this.pageIndex == 1) {
              this.dataList = res.data.data.list;
            } else {
              this.dataList = this.dataList.concat(res.data.data.list);
            }
            if (!this.finished) {
              this.pageIndex++;
            }
          }
        }
      })
    },
    onRefresh() {
      this.isLoading = false
      this.dataList = []
      this.pageIndex = 1
      this.toGetProbeTaskList(this.deptNo)
    },
    onLoad () {
      this.toGetProbeTaskList(this.deptNo)
    },
    oneChange(val){
      this.pageIndex = 1
      this.toGetProbeTaskList(val)
      this.deptNo = val
    },
    twoChange(val) {
      this.pageIndex = 1
      this.toGetProbeTaskList(val)
      this.deptNo = val
    },
    threeChange(val) {
      this.pageIndex = 1
      this.toGetProbeTaskList(val)
      this.deptNo = val
    },
  }
}
</script>

<style lang="less" scoped>
.visitSurveyList{
  min-height: 100%;
  background: #F7F8F9;
  .flex{
    display: flex;
  }
  .top-fixed{
    position: fixed;
    width: 100%;
    z-index: 10;
  }
  .data-wrap{
    padding: .24rem 0.3rem 2rem;
    .visit-item{
      background: #fff;
      margin-bottom: 0.24rem;
      box-shadow: 0 0.18rem .26rem 0 rgba(0,0,0,0.05);
      border-radius: 0.16rem;
      padding: 0.4rem 0.32rem;

      .title{
        color: #333;
        font-size: 0.3rem;
        font-weight: bold;
        word-break: break-all;
      }
      .desc{
        color: #999;
        font-size: 0.26rem;
        justify-content: space-between;
        margin-top: 0.24rem;
      }
    }
  }
  .nodata-wrap{
    padding-top: 0.5rem;
    text-align: center;
    color: #616161;
    font-size: 0.3rem;
  }
  .nodata-img{
    width: 60%;
    margin-top: 1rem;
  }
  .nextBtn {
    width: 100%;
    padding: 12px;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>